const DEFAULT_FORM_FILTERS = {
    thematic: [],
    thematicExcluded: [],
    price: null,
    url: null,
    source: null,
    adult: false,
    top: null,
    volume: null,
    index: null,
    category: null,
    keywordsCount: null,
    topTenCount: null,
    partner: [],
    keyword: null,
    lang: 'fr'
}

export { DEFAULT_FORM_FILTERS }
