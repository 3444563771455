var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column",staticStyle:{"gap":"1rem"}},[_c('nl-search-filters',{attrs:{"isGSC":"","sites":_vm.sites,"token-valid":_vm.access_token !== null},on:{"googleLogin":_vm.googleLogin,"googleRevoke":_vm.revokeToken,"search":_vm.search}}),(_vm.hasError)?_c('b-notification',{attrs:{"active":"","closable":false,"type":"is-danger is-light"}},[_vm._v(" "+_vm._s(_vm.$t('error.message.default'))+" ")]):(_vm.results.length || _vm.resultsNotMatch?.length)?_c('b-tabs',{staticClass:"box",model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[(_vm.results.length)?_c('b-tab-item',{attrs:{"label":_vm.$t('gsc.positionedKeyword'),"icon":"link"}}):_vm._e(),(_vm.resultsNotMatch?.length)?_c('b-tab-item',{attrs:{"label":_vm.$t('gsc.notPositionedKeyword'),"icon":"unlink"}},[_c('b-button',{attrs:{"type":"is-dark","label":_vm.$t('gsc.preorderKeyword')},on:{"click":function($event){return _vm.$router.push({ name: 'PreorderNew' })}}}),_c('b-table',{attrs:{"data":_vm.resultsNotMatch,"columns":[
                        {
                            field: 'keyword',
                            label: _vm.$t('global.keyword'),
                            sortable: true
                        },
                        {
                            field: 'url',
                            label: _vm.$t('global.url'),
                            sortable: true
                        },
                        {
                            field: 'position',
                            label: _vm.$t('tableau-keywords.position'),
                            sortable: true
                        },
                        {
                            field: 'impressions',
                            label: _vm.$t('tableau-keywords.impressions'),
                            sortable: true
                        },
                        {
                            field: 'clicks',
                            label: _vm.$t('tableau-keywords.clicks'),
                            sortable: true
                        }
                    ],"paginated":"","per-page":"15"}})],1):_vm._e()],1):(_vm.filters.site && _vm.loaded)?_c('b-notification',{attrs:{"active":"","closable":false,"type":"is-info is-light"}},[_vm._v(" "+_vm._s(_vm.$t('searchform.noarticlesurl'))+" ")]):(_vm.loading)?_c('div',{staticClass:"box p-5",staticStyle:{"position":"relative"}},[_c('b-loading',{attrs:{"is-full-page":false},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}})],1):_c('b-notification',{attrs:{"active":"","closable":false,"type":"is-warning is-light"}},[_vm._v(" "+_vm._s(_vm.$t('searchform.select-site'))+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }