<template>
    <div class="d-flex flex-column" style="gap: 1rem">
        <nl-search-filters
            isGSC
            :sites="sites"
            :token-valid="access_token !== null"
            @googleLogin="googleLogin"
            @googleRevoke="revokeToken"
            @search="search"
        />

        <b-notification v-if="hasError" active :closable="false" type="is-danger is-light">
            {{ $t('error.message.default') }}
        </b-notification>

        <b-tabs v-else-if="results.length || resultsNotMatch?.length" class="box" v-model="tabs">
            <b-tab-item v-if="results.length" :label="$t('gsc.positionedKeyword')" icon="link">
<!--                <Result-->
<!--                    unboxed-->
<!--                    :results="results"-->
<!--                    :projectId="projectId"-->
<!--                    :selectable="false"-->
<!--                    :displayCurrentPosition="true"-->
<!--                    :displayCurrentUrl="true"-->
<!--                    :meta="meta"-->
<!--                    @search="search"-->
<!--                    :loading="loading"-->
<!--                    :modalInfos="modalInfos"-->
<!--                />-->
            </b-tab-item>
            <b-tab-item v-if="resultsNotMatch?.length" :label="$t('gsc.notPositionedKeyword')" icon="unlink">
                <b-button
                    type="is-dark"
                    @click="$router.push({ name: 'PreorderNew' })"
                    :label="$t('gsc.preorderKeyword')"
                />
                <b-table
                    :data="resultsNotMatch"
                    :columns="[
                        {
                            field: 'keyword',
                            label: $t('global.keyword'),
                            sortable: true
                        },
                        {
                            field: 'url',
                            label: $t('global.url'),
                            sortable: true
                        },
                        {
                            field: 'position',
                            label: $t('tableau-keywords.position'),
                            sortable: true
                        },
                        {
                            field: 'impressions',
                            label: $t('tableau-keywords.impressions'),
                            sortable: true
                        },
                        {
                            field: 'clicks',
                            label: $t('tableau-keywords.clicks'),
                            sortable: true
                        }
                    ]"
                    paginated
                    per-page="15"
                />
            </b-tab-item>
        </b-tabs>

        <b-notification v-else-if="filters.site && loaded" active :closable="false" type="is-info is-light">
            {{ $t('searchform.noarticlesurl') }}
        </b-notification>

        <div v-else-if="loading" class="box p-5" style="position: relative">
            <b-loading v-model="loading" :is-full-page="false" />
        </div>

        <b-notification v-else active :closable="false" type="is-warning is-light">
            {{ $t('searchform.select-site') }}
        </b-notification>
    </div>
</template>
<script>
    import Search from '@/services/search/search'
    import NlSearchFilters from '@/components/Search/NlSearchFilters'
    import { DEFAULT_FORM_FILTERS } from '@/services/search/filters'
    import { mapActions, mapMutations, mapState } from 'vuex'
    import NlStorage from "@/services/nl.storage";

    export default {
        name: 'SearchGSC',
        title: 'searchform.searchbygsc',
        components: {
            NlSearchFilters
        },
        data: () => ({
            site: null,
            sites: [],
            resultsNotMatch: [],
            tabs: 0,
            client: null,
            access_token: null,
            locale: null,
            formFilter: DEFAULT_FORM_FILTERS
        }),
        computed: {
            ...mapState('auth', {
                security: 'security'
            })
        },
        created() {
            if (this.project) {
                this.locale = this.project.project_lang
                this.formFilter.url = this.project.website
            }
        },
        watch: {
            project(project) {
                this.locale = project.project_lang
                this.formFilter.url = project.website
                this.search()
            }
        },
        methods: {
            ...mapMutations('search', {
                setResults: 'setResults'
            }),
            ...mapActions('search', {
                prepareGSCSearch: 'prepareGSCSearch',
                getGSCKeywords: 'getGSCKeywords',
                resetSearch: 'resetSearch'
            }),
            handleCredentialResponse() {
                const search = new Search()
                search.loadSitesGSC({ code: this.access_token }, response => {
                    this.sites = response.data.sites
                })

                if (undefined !== this.$route.query.site) {
                    this.site = this.$route.query.site
                    this.search()
                }
            },
            revokeToken() {
                this.resetSearch()
                this.resultsNotMatch = []

                let token = NlStorage.getItem('oauth_token')
                window?.google?.accounts?.oauth2?.revoke(token)
                NlStorage.removeItem('oauth_token')
                this.access_token = null
            },
            search() {
                this.setResults({ data: [] })
                this.resultsNotMatch = []

                if (!this.filters.site) {
                    this.displayForm = false
                    this.filterThematic = []
                    return
                }

                this.prepareGSCSearch({
                    code: this.access_token,
                    site: this.filters.site,
                    url: this.filters.urlSearch,
                    positionMin: this.filters.positionMin,
                    positionMax: this.filters.positionMax,
                    impressionsMin: this.filters.impressionsMin,
                    impressionsMax: this.filters.impressionsMax,
                    clicksMin: this.filters.clicksMin,
                    clicksMax: this.filters.clicksMax,
                    withKeyword: this.filters.keywordSearch
                })
                    .then(data => {
                        this.hasError = false
                        if (data.success || data.nb_keywords > 0) {
                            this.canSearch = true
                            this.filter()
                        } else {
                            this.canSearch = false
                            this.displayForm = false
                        }
                    })
                    .catch(() => {
                        this.hasError = true
                        this.displayForm = false
                    })
            },
            filter() {
                if (!this.canSearch) {
                    return
                }

                if (!this.filters.site) {
                    this.displayForm = false
                    this.setResults({ data: [] })
                    this.filterThematic = []
                    return
                }

                // Clone filters without reference.
                const forms = JSON.parse(JSON.stringify(this.filters))

                this.getGSCKeywords({
                    ...Object.keys(this.filters)
                        .filter(function (k) {
                            return forms[k] != null && forms[k] !== '' && (!Array.isArray(forms[k]) || forms[k].length)
                        })
                        .reduce(function (acc, k) {
                            acc[k] = forms[k]
                            return acc
                        }, {}),
                    ...{
                        nbResults: this.meta.per_page,
                        sortBy: this.meta.sortBy,
                        orderBy: this.meta.orderBy,
                        page: this.meta.current_page,
                        lang: this.locale,
                        projectId: this.projectId,
                        code: this.access_token
                    }
                })
                    .then(data => {
                        this.hasError = false
                        this.displayForm = true

                        this.resultsNotMatch = data.keywords_not_match ?? []

                        const thematic = data.thematic_groupe
                        this.filterThematic = Object.keys(thematic).map(key => {
                            return { id: key, text: thematic[key] }
                        })
                    })
                    .catch(() => {
                        this.hasError = true
                        this.displayForm = false
                        this.setResults({ data: [] })
                        this.resultsNotMatch = []
                        this.filterThematic = []
                    })
            },
            resetFilters() {
                this.formFilter = Object.assign({}, DEFAULT_FORM_FILTERS)
                this.filter()
            },
            googleLogin() {
                let google = window?.google?.accounts?.oauth2?.initTokenClient({
                    client_id: process.env.VUE_APP_GSC_CLIENT_ID,
                    scope: 'https://www.googleapis.com/auth/webmasters.readonly',
                    callback: tokenResponse => {
                        this.access_token = tokenResponse.access_token
                        NlStorage.setItem('oauth_token', tokenResponse.access_token)
                        this.handleCredentialResponse()
                    }
                })

                google?.requestAccessToken()
            }
        },
        mounted() {
            let google = document.createElement('script')
            google.src = 'https://accounts.google.com/gsi/client'
            google.setAttribute('async', '')
            google.setAttribute('defer', '')

            document.body.appendChild(google)

            this.googleLogin()
        }
    }
</script>
